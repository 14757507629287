// import RNDateTimePicker from '@react-native-community/datetimepicker';
import {
  Alert,
  Button,
  ButtonBorder,
  Div,
  Dropdown,
  ImageView,
  Label,
} from 'components';
import {Ecolors, Icons, urlApp} from 'constant';
import md5 from 'md5';
import React, {useEffect, useRef, useState} from 'react';
import {Platform} from 'react-native';
import {getListProduct} from 'reducer/investment';
import {useAppSelector} from 'store/hooks';
// import RNFS from 'react-native-fs';
// import {PERMISSIONS} from 'react-native-permissions';
// import ReactNativeBlobUtil from 'react-native-blob-util';
import {convertTimestamp, joinObjectCalendar, PostMSG} from 'utils';
import {getStoreData, getStoreToken} from 'utils/storage';
import ItemCalendar from './ItemCalendar';
const DD = new Date();

function ButtonCalendar(p: {title?: string; onPress: () => void; value?: any}) {
  return (
    <Button
      width={163}
      onPress={() => p.onPress && p.onPress()}
      height={48}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderRadius={5}
      borderColor={Ecolors.bordercolor}
      paddingHorizontal={10}
      borderWidth={0.8}>
      <Label
        multilanguage={!p.value}
        size={14}
        // color={p.value ? Ecolors.textColor : Ecolors.grayColor}>
        color={Ecolors.textColor}>
        {!p.value ? p.title : convertTimestamp(p.value.getTime(), '', true)}
      </Label>
      <ImageView
        heightWidth={17}
        source={Icons.calendar}
        resizeMode={'contain'}
      />
    </Button>
  );
}

function Statement(p: {activeTab: number}) {
  const [product, setProduct] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const listProduct = useAppSelector(state => getListProduct(state));
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const value = useRef<'from' | 'to'>('from');
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const onChange = (e: any, a: any) => {
    if (Platform.OS === 'android') {
      setIsShowCalendar(false);
    }
    if (a) {
      if (value.current == 'from') {
        setFromDate(a);
      } else {
        setToDate(a);
      }
    }
  };

  useEffect(() => {
    if (p.activeTab == 3) {
      clearData();
    }
  }, [p.activeTab]);

  const clearData = () => {
    Promise.all([setProduct(null), setFromDate(null), setToDate(null)]);
  };

  const onAccept = async () => {
    let body = {};
    if (toDate) {
      body[`toDate`] = joinObjectCalendar({
        date: toDate.day.value,
        month: toDate.month.value,
        year: toDate.year.value,
        // isPicker: true,
      });
    }
    if (fromDate) {
      body[`fromDate`] = joinObjectCalendar({
        date: fromDate.day.value,
        month: fromDate.month.value,
        year: fromDate.year.value,
      });
    }
    body['productCode'] = product.code;
    if (loading) {
      return;
    }
    downloadConfirm(body);
  };

  const downloadConfirm = async (body: Object) => {
    try {
      setLoading(true);
      const url = `${urlApp.APIURL}api/report/transactions-history`;
      const token = await getStoreToken();
      const email = await getStoreData('email');
      const phone = await getStoreData('phone');
      const refCode = await getStoreData('refCode');
      const agentCode = await getStoreData('agentCode');
      const refKey = await getStoreData('refKey');
      // const email = 'manld1@fincorp.com.vn';
      // const phone = `0765000655`;
      // const refCode = `7292d7660bb1a5b7e214ca297ba04fe9fb3de293`;
      // const agentCode = `924`;
      // const refKey = `4a84351eab64d151a34df1573ec9d411b4c6163d`;
      var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // email: email,
        // phone: phone,
        // refCode: refCode,
        // agentCode: agentCode,
        Authorization: token
          ? `Bearer ${token}`
          : `Bearer ${md5(`${email}${phone}${refCode}${agentCode}${refKey}`)}`,
      };
      const data: any = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      });
      const name = data?.headers
        ?.get(`content-disposition`)
        .replace('attachment; filename="', '')
        .replace(/"/g, '');
      data.blob().then(t => {
        PostMSG('DownloadTransactionStatementFile', {
          blob: data,
        });
        let dataurl = window.URL.createObjectURL(t);
        let a = document.createElement('a');
        a.href = dataurl;
        a.download = name;
        a.click();
      });
    } catch (error: any) {
      Alert.showError({
        content: error.message,
        multilanguage: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div
      padding={16}
      paddingTop={0}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      flex={1}>
      <Label fontWeight={'700'}>{`transactionscreen.chonquy`}</Label>
      <Dropdown
        multiline={true}
        marginTop={6}
        paddingHorizontal={0}
        initData={listProduct}
        value={product}
        multilanguage={true}
        content={`createordermodal.chonsanpham`}
        isActive={true}
        onChange={setProduct}
      />
      <Label
        fontWeight={'700'}
        marginTop={14}>{`transactionscreen.theothoidiemgiaodich`}</Label>
      <Div
        paddingTop={16}
        paddingBottom={23}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <ItemCalendar
          title={'Từ ngày'}
          onChangeTime={(t: any) => {
            setFromDate(t);
          }}
          // initValue={`${DD.getFullYear()}/${DD.getMonth() + 1}/${1}`}
        />
        <ItemCalendar
          title={'Đến ngày'}
          onChangeTime={(t: any) => {
            setToDate(t);
          }}
          // initValue={`${DD.getFullYear()}/${DD.getMonth() + 1}/${DD.getDate()}`}
        />
        {/* <ButtonCalendar
          value={fromDate}
          onPress={() => {
            setIsShowCalendar(true);
            value.current = 'from';
          }}
          title={`transactionscreen.tungay`}
        />
        <ButtonCalendar
          value={toDate}
          onPress={() => {
            setIsShowCalendar(true);
            value.current = 'to';
          }}
          title={`transactionscreen.toingay`}
        /> */}
      </Div>
      <ButtonBorder
        loading={loading}
        isDisable={!product || !fromDate || !toDate}
        onPress={() => {
          if (loading) {
            return;
          }
          onAccept();
        }}
        title={`transactionscreen.saoke`}
        width={343}
        marginTop={26}
      />
      {/* {Platform.OS === 'android'
        ? (isShowCalendar && (
            <RNDateTimePicker
              themeVariant="dark"
              display={'spinner'}
              textColor={'#000'}
              value={
                value.current == 'from'
                  ? fromDate || new Date()
                  : toDate || new Date()
              }
              onChange={onChange}
            />
          )) || <></>
        : (isShowCalendar && (
            <>
              <Div
                flexDirection={'row'}
                alignItems={'center'}
                backgroundColor={Ecolors.whiteColor}
                justifyContent={'flex-end'}>
                <Button
                  padding={10}
                  onPress={() => {
                    setIsShowCalendar(false);
                    if (value.current == 'from') {
                      if (!fromDate) {
                        setFromDate(new Date());
                      }
                    } else {
                      if (!toDate) {
                        setToDate(new Date());
                      }
                    }
                  }}>
                  <Label
                    fontWeight={'bold'}
                    color={Ecolors.linkColor}>{`alert.lichxong`}</Label>
                </Button>
              </Div>
              <RNDateTimePicker
                themeVariant="dark"
                display={'spinner'}
                textColor={'#000'}
                value={
                  value.current == 'from'
                    ? fromDate || new Date()
                    : toDate || new Date()
                }
                onChange={onChange}
              />
            </>
          )) || <></>} */}
    </Div>
  );
}

export default React.memo(Statement);
