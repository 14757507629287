import * as React from 'react';
var countTime: any = React.createRef();
var textfilter: string = '';

const getTextFilter = () => {
  return textfilter || '';
};
const setTextFilter = (t: string) => {
  textfilter = t;
  return;
};
export {countTime, getTextFilter, setTextFilter};
