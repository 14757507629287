import axios, {AxiosError, AxiosResponse} from 'axios';
import {urlApp} from 'constant';
import md5 from 'md5';
import {getStoreData, getStoreToken} from 'utils/storage';

const baseURL = urlApp.APIURL;
const axiosApp = axios.create({
  baseURL: baseURL + 'api/',
  timeout: 60000,
});
const aa = axios.create({
  baseURL: baseURL + 'api/',
  timeout: 60000,
});

axiosApp.interceptors.request.use(
  async (config: any) => {
    const token = await getStoreToken();
    const idNo = await getStoreData('idNo');
    const phone = await getStoreData('phone');
    const refCode = await getStoreData('refCode');
    const agentCode = await getStoreData('agentCode');
    const secretKey = await getStoreData('secretKey');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      config.headers['Authorization'] = `Bearer`;
    }
    if (idNo && phone && refCode && agentCode && secretKey) {
      config.headers['Authorization'] = `Bearer ${md5(
        `${idNo}${phone}${refCode}${agentCode}${secretKey}`,
      )}`;
      config.headers['idNo'] = idNo;
      config.headers['phone'] = phone;
      config.headers['refCode'] = refCode;
      config.headers['agentCode'] = agentCode;
    } else if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      config.headers['Authorization'] = `Bearer `;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error: any) => Promise.reject(error),
);

axiosApp.interceptors.response.use(
  (res: AxiosResponse<{content: any; message: string; result: number}>) => {
    return res;
  },
  (
    err: AxiosError<{
      data?: {
        message?: string;
        messageEn?: string;
        content?: any;
        data?: any;
        result?: number;
      };
    }>,
  ) => {
    throw (
      err.response?.data || {
        message: 'Lỗi mạng',
        messageEn: 'Lỗi mạng',
        content: null,
        result: -1,
        data: null,
      }
    );
  },
);
aa.interceptors.request.use(
  async (config: any) => {
    const token = await getStoreToken();
    const idNo = await getStoreData('idNo');
    const phone = await getStoreData('phone');
    const refCode = await getStoreData('refCode');
    const agentCode = await getStoreData('agentCode');
    const secretKey = await getStoreData('secretKey');
    if (idNo && phone && refCode && agentCode && secretKey) {
      config.headers['Authorization'] = `Bearer ${md5(
        `${idNo}${phone}${refCode}${agentCode}${secretKey}`,
      )}`;
      config.headers['idNo'] = idNo;
      config.headers['phone'] = phone;
      config.headers['refCode'] = refCode;
      config.headers['agentCode'] = agentCode;
    } else if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      config.headers['Authorization'] = `Bearer `;
    }
    config.headers['Content-Type'] = 'multipart/form-data';
    config.headers['Accept'] = 'application/json';
    return config;
  },
  (error: any) => Promise.reject(error),
);

aa.interceptors.response.use(
  (res: AxiosResponse<{content: any; message: string; result: number}>) => {
    return res;
  },
  (
    err: AxiosError<{
      data?: {
        message?: string;
        messageEn?: string;
        content?: any;
        data?: any;
        result?: number;
      };
    }>,
  ) => {
    throw (
      err.response?.data || {
        message: 'Lỗi mạng',
        messageEn: 'Lỗi mạng',
        content: null,
        result: -1,
        data: null,
      }
    );
  },
);

export async function doGetAxios(url: string): Promise<IAxiosResponse> {
  try {
    const res: AxiosResponse = await axiosApp.get(url);
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error) {
    throw error;
  }
}

export async function doPostAxios(
  url: string,
  params: object,
): Promise<IAxiosResponse> {
  try {
    const res: AxiosResponse = await axiosApp.post(url, JSON.stringify(params));
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error) {
    throw error;
  }
}

export async function axiosMultipart(
  url: string,
  params: any,
): Promise<IAxiosResponse> {
  try {
    const res: AxiosResponse = await aa.post(url, params);
    if (res) {
      return res.data;
    }
    throw res;
  } catch (error) {
    throw error;
  }
}

export {axiosApp};
