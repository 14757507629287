import {Alert, ButtonBorder, Div, HeaderBack} from 'components';
import {Ecolors, stringApp} from 'constant';
import {Parser} from 'html-to-react';
import React, {useState} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {apiAuth, navigate} from 'services';

const T = (props: {title: string}) => {
  return (
    <Div
      flexDirection={'row'}
      alignItems={'center'}
      paddingLeft={22}
      paddingRight={15}
      justifyContent={'flex-start'}>
      <Div
        widthHeight={7}
        backgroundColor={Ecolors.textColor}
        borderRadius={10}
        marginRight={10}
      />
      {Parser().parse(props.title)}
    </Div>
  );
};

function DeleteAccountModal() {
  const insests = useSafeAreaInsets();
  const [loading, setLoading] = useState<boolean>(false);
  const content1 = `<p>Tài khoản sau khi xoá không thể khôi phục lại được</p>`;
  const content2 = `<p>Sau khi tài khoản bị xoá, bạn <strong>không</strong> thể tạo lại tài khoản với <strong>số điện thoại</strong> hoặc số <strong>CMND/CCCD</strong> này được.</p>`;
  const content3 = `<p>Tài khoản chỉ được xoá khi không còn tài sản tại ${stringApp.companyName}.</p>`;

  const onDelete = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const res = await apiAuth.deleteAccount();
      if (res.status == 200) {
        navigate('OtpRequestModal', {
          data: {
            requestOnSendOtp: res.data,
            flowApp: 'DeleteAccount',
          },
        });
      } else {
        Alert.showError({
          multilanguage: false,
          content: res.message,
        });
      }
    } catch (error: any) {
      Alert.showError({
        multilanguage: false,
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Div
      height={'100%'}
      backgroundColor={Ecolors.whiteColor}
      flexDirection={'column'}
      alignItems={'center'}>
      <HeaderBack type={2} title={`settingscreen.yeucauxoataikhoan`} />
      <Div flex={1}>
        <T title={content1} />
        <T title={content2} />
        <T title={content3} />
      </Div>
      <ButtonBorder
        loading={loading}
        title={`settingscreen.yeucauxoa`}
        onPress={() => {
          onDelete();
        }}
      />
      <Div height={insests.bottom + 30} />
    </Div>
  );
}

export default React.memo(DeleteAccountModal);
