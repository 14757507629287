import {Button, Div, Label} from 'components';
import {Ecolors} from 'constant';
import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {widthScreen} from 'utils';
const currentYear = new Date().getFullYear();
const listMonth = [
  {
    content: 'January',
    value: '01',
    numday: 31,
    index: 0,
  },
  {
    content: 'February',
    value: '02',
    numday: 28,
    index: 1,
    numdayplus: 29,
  },
  {
    content: 'March',
    value: '03',
    index: 2,
    numday: 31,
  },
  {
    content: 'April',
    index: 3,
    value: '04',
    numday: 30,
  },
  {
    content: 'May',
    value: '05',
    index: 4,
    numday: 31,
  },
  {
    content: 'June',
    index: 5,
    value: '06',
    numday: 30,
  },
  {
    content: 'July',
    index: 6,
    value: '07',
    numday: 31,
  },
  {
    content: 'August',
    index: 7,
    value: '08',
    numday: 31,
  },
  {
    content: 'September',
    index: 8,
    value: '09',
    numday: 30,
  },
  {
    content: 'October',
    index: 9,
    value: '10',
    numday: 31,
  },
  {
    content: 'November',
    index: 10,
    value: '11',
    numday: 30,
  },
  {
    content: 'December',
    index: 11,
    value: '12',
    numday: 31,
  },
];
const listYear = Array(15)
  .fill(0)
  .map((item, index) => {
    const t = currentYear - 15 + index + 1;
    return {
      content: t,
      value: t,
      index,
    };
  });
const Item = ({item, index, onPressItem, isFocus}) => {
  return (
    <Button
      justifyContent="center"
      onPress={() => {
        onPressItem && onPressItem(index);
      }}
      alignItems={'center'}
      style={{
        width: (widthScreen - 40) / 3,
        height: 50,
      }}>
      <Label multilanguage={false}>{item?.content || index}</Label>
    </Button>
  );
};
const ItemSpace = () => {
  return (
    <Div
      style={{
        width: (widthScreen - 40) / 3,
        height: 50,
      }}
    />
  );
};

const List = (p: {
  data: Array<any>;
  value: any;
  onChange: (t: any) => void;
}) => {
  const {data, value, onChange} = p;
  const [itemFocus, setItemFocus] = useState<any>(null);
  const flatlistRef = useRef<any>();

  const onViewableItemsChanged = React.useRef((res: any) => {
    // const itemViewport = res?.viewableItems?.[0]?.item;
    // setItemFocus(itemViewport);
  });

  useEffect(() => {
    onChange && onChange(itemFocus);
  }, [itemFocus]);

  useEffect(() => {
    const item = p.data?.find(
      (item: any, index: number) => item.value == p.value?.value,
    );
    if (flatlistRef.current) {
      flatlistRef.current.scrollToIndex({
        index: !!item ? item?.index || 0 : p.data.length - 1,
        animated: false,
      });
    }
    return () => {};
  }, [p.value]);

  const renderItem = ({item, index}) => {
    return (
      <Item
        isFocus={itemFocus?.value == item.value}
        onPressItem={i => {
          flatlistRef.current.scrollToIndex({
            index: i,
            animated: true,
            useNativeDriver: true,
          });
        }}
        item={item}
        index={index}
      />
    );
  };
  const ListHeaderComponent = () => {
    return (
      <>
        <ItemSpace />
        <ItemSpace />
      </>
    );
  };
  const ListFooterComponent = () => {
    return (
      <>
        <ItemSpace />
        <ItemSpace />
      </>
    );
  };

  const onScroll = ({nativeEvent}) => {
    const index = Math.round(nativeEvent.contentOffset.y / 50);
    if (index != itemFocus?.index) {
      setItemFocus(data[index]);
    }
  };
  return (
    <FlatList
      ref={flatlistRef}
      onViewableItemsChanged={onViewableItemsChanged.current}
      showsVerticalScrollIndicator={false}
      data={data}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListFooterComponent}
      extraData={[data]}
      renderItem={renderItem}
      keyExtractor={(item, index) => `key${index}`}
      snapToAlignment={'start'}
      onScroll={onScroll}
      snapToInterval={50}
      getItemLayout={(data, index) => ({
        length: 50,
        offset: 50 * index,
        index,
      })}
    />
  );
};

const Calendar = (p: {
  onCancel: () => void;
  onAccept: (t: any) => void;
  type?: 'all' | 'month' | 'year';
  value?: any;
}) => {
  const insets = useSafeAreaInsets();
  const [listDay, setListDay] = useState<Array<any>>(
    Array(31)
      .fill(0)
      .map((item: any, index: number) => {
        const t = `0000${index + 1}`;
        return {
          value: t.slice(t.length - 2),
          content: t.slice(t.length - 2),
          index,
        };
      }),
  );
  const [day, setDay] = useState<any>(null);
  const [month, setMonth] = useState<any>(listMonth[listMonth.length - 1]);
  const [year, setYear] = useState<any>(listYear[listYear.length - 1]);

  const funcSetlistDay = (num: number) => {
    const ar = Array(num)
      .fill(0)
      .map((item: any, index: number) => {
        const t = `0000${index + 1}`;
        return {
          value: t.slice(t.length - 2),
          content: t.slice(t.length - 2),
          index,
        };
      });
    setListDay(ar);
    setDay(ar[ar.length - 1]);
  };

  useEffect(() => {
    funcSetlistDay(31);
    return () => {};
  }, []);

  const switchCaseType = () => {
    switch (p.type) {
      case 'month':
        return (
          <>
            <List
              onChange={(t: any) => {
                setMonth(t);
              }}
              data={listMonth}
              value={p.value?.month}
            />
          </>
        );
      case 'year':
        return (
          <>
            <List
              onChange={(t: any) => {
                setYear(t);
              }}
              data={listYear}
              value={p.value?.year}
            />
          </>
        );
      default:
        return (
          <>
            <List
              onChange={(t: any) => {
                setDay(t);
              }}
              data={listDay}
              value={p.value?.day}
            />
            <List
              onChange={(t: any) => {
                setMonth(t);
              }}
              data={listMonth}
              value={p.value?.month}
            />
            <List
              onChange={(t: any) => {
                setYear(t);
              }}
              data={listYear}
              value={p.value?.year}
            />
          </>
        );
    }
  };

  return (
    <Div
      style={{
        width: widthScreen,
        marginBottom: insets.bottom + 40,
        alignItems: 'center',
      }}>
      <Div
        overflow="hidden"
        backgroundColor={Ecolors.whiteColor}
        borderRadius={5}
        marginBottom={14}
        style={{
          height: 298,
        }}>
        <Div
          style={{
            height: 250,
          }}
          width={345}
          alignItems={'center'}
          justifyContent={'center'}>
          <Div
            style={{
              width: '95%',
              height: 50,
              backgroundColor: Ecolors.spaceColor,
              borderRadius: 5,
            }}
          />
          <Div
            position="absolute"
            zIndex={999}
            flexDirection={'row'}
            justifyContent={'space-between'}
            style={StyleSheet.absoluteFillObject}>
            {switchCaseType()}
          </Div>
        </Div>
        <Button
          borderTopColor={Ecolors.bordercolor}
          onPress={() => {
            let obj = {};
            if (p.type == 'month') {
              obj['month'] = month;
            } else if (p.type == 'year') {
              obj['year'] = year;
            } else {
              obj = {
                day,
                month,
                year,
              };
            }
            p.onAccept(obj);
          }}
          borderTopWidth={1}
          width={345}
          height={48}
          backgroundColor={Ecolors.whiteColor}
          alignItems={'center'}
          justifyContent={'center'}>
          <Label
            size={14}
            color={Ecolors.blue}
            multilanguage={false}>{`Xác nhận`}</Label>
        </Button>
      </Div>

      <Button
        width={345}
        height={48}
        onPress={() => {
          p.onCancel();
        }}
        backgroundColor={Ecolors.whiteColor}
        borderRadius={5}
        alignItems={'center'}
        justifyContent={'center'}>
        <Label
          size={14}
          color={Ecolors.blue}
          multilanguage={false}>{`Bỏ qua`}</Label>
      </Button>
    </Div>
  );
};

export default React.memo(Calendar);
