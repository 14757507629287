import {stringApp} from 'constant';
export default {
  footerapp: {
    OverviewScreen: 'Tổng quan',
    TransactionScreen: 'Giao dịch',
    InvestmentScreen: 'Sản phẩm',
    AssetScreen: 'Tài sản',
    ProfileScreen: 'Tài khoản',
  },
  splashscreen: {
    hi: `Chào mừng bạn đến với ${stringApp.companyName}`,
    continue: 'Tiếp tục',
  },
  loginscreen: {
    login: 'Đăng nhập',
    saveusername: 'Lưu',
    forgotpass: 'Quên mật khẩu ?',
    signuptitle: 'Bạn chưa có tài khoản? ',
    signup: 'Đăng ký ngay!',
    accountplacehoder: 'Nhập số điện thoại',
    passwordplacehoder: 'Nhập mật khẩu',
    fincorp: `${stringApp.companyName}`,
    thefintechdeveloper: 'The fintech developer',
    welcome: `Welcome to ${stringApp.companyName}!`,
    tendangnhap: 'Tên đăng nhập',
    matkhau: 'Mật khẩu',
    kichhoattaikhoan: 'Kích hoạt tài khoản',
    warning: `Nếu Nhà đầu tư có hơn 01 tài khoản, liên hệ DFVN để được hướng dẫn`,
  },
  reviewinfoscreen: {
    hosocanhan: 'Hồ sơ cá nhân',
    thongtincanhan: 'Thông tin cá nhân',
    hoantathosodangky: 'Hoàn tất hồ sơ đăng ký',
    //
    thongtintaikhoannganhang: 'Thông tin tài khoản ngân hàng',
    tenchutaikhoan: 'Tên chủ tài khoản',
    sotaikhoan: 'Số tài khoản',
    tennganhang: 'Tên ngân hàng',
    chinhanh: 'Chi nhánh',
    //
    thongtinnhadautu: '1. Thông tin nhà đầu tư',
    hoten: 'Họ tên',
    gioitinh: 'Giới tính',
    ngaysinh: 'Ngày sinh',
    quoctich: 'Quốc tịch',
    email: 'Email',
    sodienthoai: 'Số điện thoại',
    thongtingiayto: '2. Thông tin giấy tờ tuỳ thân/mã GD chứng khoán',
    note: 'Chú ý: Mã giao dịch chứng khoán chỉ dành cho người nước ngoài',
    loaigiayto: 'Loại giấy tờ',
    sohieugiayto: 'Số hiệu giấy tờ',
    ngaycap: 'Ngày cấp',
    noicap: 'Nơi cấp',
    taihinhanh: 'Tải hình ảnh giấy tờ tuỳ thân/Mã GD chứng khoán của bạn',
    //
    thongtindiachi: 'Thông tin địa chỉ',
    diachithuongtru: '1. Địa chỉ thường trú',
    quocgia: 'Quốc gia',
    tinhthanhpho: 'Tỉnh/Thành phố',
    quanhuyen: 'Quận/huyện',
    phuongxa: 'Phường/Xã',
    sonhatenduong: 'Số nhà, tên đường',
    diachilienhe: '2. Địa chỉ liên hệ',
    giongdiachithuongtru: 'Giống địa chỉ thường trú',
    diachikhac: 'Địa chỉ khác',
    trong: '',
    //
    dieukhoandieukienmotaikhoan: 'Điều khoản điều kiện mở tài khoản',
    dieukhoansudung: 'Điều khoản sử dụng',
    content: `Sau khi hoàn thành bước xác nhận này, thông tin hợp đồng mở tài khoản sẽ được gửi về email \nQuý khách vui lòng in, ký xác nhận và gửi thư về địa chỉ: \n - Bộ phận Chăm sóc Khách hàng Công ty Cổ phần Quản lý Quỹ ${stringApp.companyName}:\n Lầu 17, toà nhà Sunwah, 115 Nguyễn Huệ, Phường Bến Nghé, Quận 1, Tp.Hồ Chí Minh (84) 28 3827 8535 \n - Trụ sở chính: \n Lầu 17, toà nhà Sunwah, 115 Nguyễn Huệ, Phường Bến Nghé, Quận 1, Tp.Hồ Chí Minh (84) 28 3827 8535 \n - Văn phòng đại diện tại Hà Nội: Lầu 6, International Centre, 17 Ngô Quyền, Q.Hoàn Kiếm, Hà Nội (84) 24 3936 4630`,
    //
    hoantat: 'Hoàn tất',
    danhgiamucdoruiro: 'Đánh giá mức độ rủi ro',
  },
  forgotpasswordscreen: {
    forgotpassword: 'Quên mật khẩu',
    content: `Bạn vui lòng nhập tên đăng nhập đã đăng ký và thực hiện theo hướng dẫn.`,
    continue: 'Tiếp tục',
    tendangnhap: 'Tên đăng nhập',
  },
  registerscreen: {
    createaccount: 'Tạo tài khoản',
    fullname: 'Họ và tên đầy đủ',
    email: 'Email',
    phonenumber: 'Số điện thoại',
    userrefcode: 'Người giới thiệu',
    continue: 'Tiếp tục',
    tieptuc: 'Tiếp tục',
    signintitle: 'Bạn đã có tài khoản? ',
    signin: 'Đăng nhập',
    dangnhap: 'Đăng nhập!',
    dangki: 'Đăng ký',
    hoantoanmienphivaratdongian: 'Miễn phí - Đơn giản - Dễ dàng',
    hotendaydu: 'Họ tên đầy đủ',
    magioithieucuanguoibanhang: 'Mã giới thiệu của người bán hàng',
    thongtinbatbuoc: 'Thông tin bắt buộc',
    tendaydubatbuocnhap: 'Tên đầy đủ bắt buộc nhập',
    saidinhdangemail: 'Sai định dạng email',
    saisodienthoai: 'Sai số điện thoại',
    thongtinkhongduocdetrong: 'Thông tin không được để trống',
    titletrong: '',
    emailkhongduocdetrong: 'Email bắt buộc nhập',
    sodienthoaikhongduocdetrong: 'Số điện thoại bắt buộc nhập',
  },
  activeaccountscreen: {
    kichhoattaikhoan: 'Kích hoạt tài khoản',
    titletrong: '',
    sodienthoaidadangky: 'Số điện thoại đã đăng ký',
    placehoder: 'Số điện thoại, ví dụ: 09123456789',
    tieptuc: 'Tiếp tục',
    contentactive:
      'Quý khách vui lòng cung cấp đúng số điện thoại đã đăng ký tài khoản giao dịch',
  },
  setpasswordscreen: {
    craetepass: 'Tạo mật khẩu',
    baomatthongtincanhan: 'Bảo mật thông tin cá nhân',
    rule: `Chiều dài tối thiểu là 6, bao gồm chữ thường (a-z) và chữ in hoa (A-Z), chứa ít nhất một số (0-9) hoặc ký hiệu đặc biệt`,
    namelogin: 'Tên đăng nhập',
    password: 'Mật khẩu',
    repassword: 'Nhập lại mật khẩu',
    accept: 'Chấp nhận',
    create: 'Đăng ký',
    dangnhap: 'Đăng nhập',
    taomatkhau: 'Tạo mật khẩu ',
    tendangnhap: 'Tên đăng nhập',
    rule1: 'Chiều dài tối thiểu là 6 và tối đa là 40',
    rule2: 'Bao gồm chữ thường (a-z) và chữ in hoa (A-Z)',
    rule3: 'Chứa ít nhất một số (0-9) hoặc ký hiệu đặc biệt',
    matkhau: 'Mật khẩu',
    nhaplaimatkhau: 'Nhập lại mật khẩu',
    matkhaubaogom: 'Mật khẩu bao gồm:',
    tieptuc: 'Tiếp tục',
    saidinhdangmatkhau: 'Sai định dạng mật khẩu!',
    xacnhanmatkhaukhongdung: 'Xác nhận mật khẩu không đúng!',
    titletrong: '',
    thongtinkhongduocdetrong: 'Thông tin không được để trống',
    vuilongnhapmatkhau: 'Vui lòng nhập mật khẩu',
    vuilongnhaplaimatkhau: 'Vui lòng nhập lại mật khẩu',
    xacthuctaikhoan: 'Xác thực tài khoản',
  },
  otprequestmodal: {
    confirminformation: 'Xác thực giao dịch',
    content: `Vui lòng nhập mã xác thực (OTP) đã được gửi đến số điện thoại `,
    confirm: 'Xác thực',
    resendotp: 'Gửi lại mã OTP',
    contenttiming: 'Mã OTP sẽ hết hạn trong vòng ',
    otptimeout: 'Mã OTP đã hết hạn',
  },
  loading: 'Đang tải',
  profile: {
    taikhoan: 'Tài khoản',
    file: 'Hồ sơ cá nhân',
    logininfo: 'Thông tin đăng nhập',
    changepassword: 'Thay đổi mật khẩu',
    hosogoc: 'Hồ sơ gốc',
    changeemail: 'Thay đổi email',
    changepermanentaddress: 'Thay đổi địa chỉ thường trú',
    changeaddress: 'Thay đổi địa chỉ liên hệ',
    logout: 'Đăng xuất',
    //trangthaitkdautu: 'Trạng thái TK đầu tư: ',
    hosomotaikhoan: 'Hồ sơ mở tài khoản',
    sotaikhoan: 'Số TK - ',
    contentlogininfo: 'Bạn có muốn lưu thông tin đã thay đổi không?',
    change: 'Thay đổi',
    email: 'Email',
    phone: 'Số điện thoại',
    password: 'Mật khẩu',
    userref: 'Người giới thiệu',
    cannotchange: 'Không thể thay đổi',
    chukyso: 'Chữ ký số',
    // list
    trangthaihoso: 'Trạng thái hồ sơ',
    thongtintaikhoan: 'Hồ sơ cá nhân',
    thaydoimatkhau: 'Thay đổi mật khẩu',
    thaydoisodienthoai: 'Thay đổi số điện thoại',
    thaydoiemail: 'Thay đổi email',
    hotrokhachhang: 'Hỗ trợ khách hàng',
    hotline: 'Hotline',
    caidatvabaomat: 'Cài đặt & bảo mật',
    dangxuat: 'Đăng xuất',
    //
    oldpassword: 'Mật khẩu cũ',
    newpassword: 'Mật khẩu mới',
    renewpassword: 'Xác nhận mật khẩu',
    save: 'Lưu',
    rulepassword: `Mật khẩu bao gồm \n* Chiều dài tối thiểu là 6\n* Bao gồm chữ thường (a-z) và chữ in hoa (A-Z)\n* Chứa ít nhất một số (0-9) hoặc một kí tự đặc biệt `,
    //
    oldemail: 'Email cũ',
    newemail: 'Email mới',
    renewemail: 'Xác nhận Email',
    //
    address: 'Số nhà và tên đường',
    placeholderaddress: 'Điền số nhà, tên đường',
    nation: 'Quốc gia',
    city: 'Tỉnh/Thành phố',
    district: 'Quận/Huyện',
    commune: 'Phường/Xã',
    emailsaidinhdang: 'Email sai định dạng',
    matkhauchuadumanh: 'Mật khẩu chưa đủ mạnh',
    //
    saidinhdangmatkhau: 'Sai định dạng mật khẩu!',
    xacnhanmatkhaukhongdung: 'Xác nhận mật khẩu không đúng!',
    titletrong: '',
    thongtinkhongduocdetrong: 'Thông tin không được để trống',
  },
  // account verify screen
  accountverify: {
    luuy: 'Lưu ý: ',
    thongtintaikhoan: 'Thông tin tài khoản',
    thongtintaikhoannganhang: '1. Thông tin tài khoản ngân hàng',
    thongtinkhac: '2. Thông tin khác',
    thongtincanhan: 'Thông tin cá nhân',
    thongtinnganhang: 'Thông tin tài khoản ngân hàng',
    thongtindiachi: 'Thông tin địa chỉ',
    xacthuchoantat: 'Xác nhận hoàn tất',
    hopdongdientu: 'Giấy đăng ký mở tài khoản giao dịch',
    // trangthaitkdautu: 'Trạng thái tài khoản đầu tư',
    vuilongchonquocgia: 'Vui lòng chọn quốc gia',
    vuilongchontinhthanhpho: 'Vui lòng chọn tỉnh/thành phố',
    vuilongchonquanhuyen: 'Vui lòng chọn quận/huyện',
    vuilongchonphuongxa: 'Vui lòng chọn phường xã',
    chondiachilienhe: 'Chọn địa chỉ lên hệ',
    vuilongchondiachilienhe: 'Vui lòng chọn địa chỉ liên hệ',
    danhgiamucdoruiro: 'Đánh giá mức độ rủi ro',
    guithongtin: 'Gửi thông tin',
    //
    nghenghiep: 'Nghề nghiệp',
    chucvu: 'Chức vụ',
    mucthunhaphangthang: 'Mức thu nhập hàng tháng',
    nguontiendautu: 'Nguồn tiền đầu tư',
    //
    sonhatenduong: 'Số nhà, tên đường',

    hosogoc: 'Hồ sơ gốc',
    quocgia: 'Quốc gia',
    tinhthanhpho: 'Tỉnh/Thành phố',
    tinhthanhphotheodiachilienhe: 'Tỉnh/Thành phố (theo địa chỉ liên hệ)',
    quanhuyen: 'Quận/Huyện',
    phuongxa: 'Phường/Xã',
    // account info modal
    thongtinnhadautu: '1. Thông tin nhà đầu tư',
    hoten: 'Họ tên',
    ngaysinh: 'Ngày sinh',
    gioitinh: 'Giới tính',
    quoctich: 'Quốc tịch',
    nam: 'Nam',
    nu: 'Nữ',
    email: 'Email',
    sodienthoai: 'Số điện thoại',
    thongtincmnd: '2. Thông tin giấy tùy thân/mã GD chứng khoán',
    loaigiayto: 'Loại giấy tờ',
    sohieugiayto: 'Số hiệu giấy tờ',
    ngaycap: 'Ngày cấp',
    cmnd: 'CMND',
    magiaodichchungkhoan: 'Mã giao dịch chứng khoán',
    noicap: 'Nơi cấp',
    taihinhanh: 'Tải hình ảnh giấy tờ tùy thân/Mã GD chứng khoán của bạn',
    notetaihinhanh:
      'Giấy tờ còn hạn sử dụng, đủ ánh sáng, là hình gốc, không scan và photocopy',
    taianhmattruoc: 'Tải ảnh mặt trước',
    taianhmatsau: 'Tải ảnh mặt sau',
    vuilongchonloaigiayto: 'Vui lòng chọn loại giấy tờ',
    chuy: 'Chú ý: Mã giao dịch chứng khoán chỉ dành cho người nước ngoài',
    // bank info modal
    tenchutaikhoan: 'Tên chủ tài khoản',
    sotaikhoan: 'Số tài khoản',
    tennganhang: 'Tên ngân hàng',
    chinhanh: 'Chi nhánh',
    content:
      'Thông tin bắt buộc và thông tin tài khoản này được chuyển khoản khi thực hiện lệnh bán.',
    vuilongchonnganhang: 'Vui lòng chọn ngân hàng',
    vuilongchonchinhanh: 'Vui lòng chọn chi nhánh',
    // address info modal
    diachilienhe: 'Địa chỉ liên hệ',
    giongdiachithuongtru: 'Giống địa chỉ thường trú',
    diachikhac: 'Địa chỉ khác',
    diachithuongtru: 'Địa chỉ thường trú',
    // quyyen
    dieukhoan: 'Điều khoản',
    xacnhanhoantat: 'Xác nhận hoàn tất',
    hoantat: 'Hoàn tất',
    toidongyvoidieukhoantren: 'Tôi đồng ý với điều khoản trên',
    contentinhoso:
      'Quý khách vui lòng in, ký xác nhận và gửi thư về địa chỉ của công ty trong phần liên hệ!',
    tongdongyvoidieukhoanfatca:
      'Tôi đồng ý trả lời: Không cho tất cả các câu hỏi liên quan đến FATCA',
    contentdiachi1:
      'Sau khi hoàn thành bước xác nhận này, thông tin hợp đồng mở tài khoản sẽ được gửi về email: ',
    contentdiachi2:
      'Quý khách vui lòng in, ký xác nhận và gửi thư về địa chỉ: ',
    contentdiachi3: `- Bộ phận Chăm sóc Khách hàng Công ty Cổ phần Quản lý Quỹ ${stringApp.companyName}:\n Lầu 17, toà nhà Sunwah, 115 Nguyễn Huệ, Phường Bến Nghé, Quận 1, Tp.Hồ Chí Minh \n(84) 28 3827 8535 \n - Trụ sở chính: \n Lầu 17, toà nhà Sunwah, 115 Nguyễn Huệ, Phường Bến Nghé, Quận 1, Tp.Hồ Chí Minh \n(84) 28 3827 8535 \n - Văn phòng đại diện tại Hà Nội: Lầu 6, International Centre, 17 Ngô Quyền, Q.Hoàn Kiếm, Hà Nội \n(84) 24 3936 4630`,
    titleconfirm:
      'Để bắt đầu thực hiện giao dịch, bạn cần xác nhận thông tin và đồng ý với các điều khoản và điều kiện bên dưới:',
    //
    contentconfirm: `1.Bản công bố rủi ro khi đầu tư vào quỹ mở.\n\nTôi, Nhà Đầu Tư, đã xem xét các thông tin được cung cấp về quỹ mở, và hiểu quỹ mở là gì.\n
Tôi, Nhà Đầu Tư, hiểu tôi đang tham gia vào một sản phẩm đầu tư tại thị trường chứng khoán Việt Nam, bao gồm cổ phiếu, trái phiếu, và các công cụ tài chính khác. Tôi hiểu rằng các khoản đầu tư vào chứng khoán sẽ chịu tác động từ rủi ro của thị trường, và vì vậy mà có thể thay đổi tăng hoặc giảm giá trị của khoản đầu tư.\n
Mức sinh lời kỳ vọng (nếu có) của Tôi trong các quỹ mở hoàn toàn không được cam kết hay đảm bảo. Vì vậy, Tôi hiểu rằng mức sinh lời thực tế có thể cao hơn hoặc thấp hơn mức sinh lời kỳ vọng hoặc mức sinh lời trong quá khứ của quỹ.\n
Khoản đầu tư vào quỹ mở của Tôi có thể phải chịu thua lỗ nếu các chứng khoán được đầu tư vào không hoạt động hiệu quả hoặc thị trường không thuận lợi. Từ đó Tôi hiểu rằng một phần khoản đầu tư của tôi có thể bị mất mát do mức sinh lời của quỹ không như kỳ vọng.\n
${stringApp.companyName} hoặc đối tác của ${stringApp.companyName} không thể đưa ra bất kỳ cam kết nào về lợi nhuận hay thua lỗ trên khoản đầu tư, cho dù là một cách trực tiếp hay gián tiếp. Tôi hiểu rằng ${stringApp.companyName} và đối tác của ${stringApp.companyName} không chịu và không có bất kỳ nghĩa vụ pháp lý nào phải bồi thường cho bất cứ khoản lỗ nào đối với các khoản đầu tư của Tôi vào quỹ mở.\n\n2.Cam kết của nhà đầu tư khi đầu tư vào quỹ mở\n\nTôi có trách nhiệm am hiểu rõ Bản cáo bạch và Điều lệ của Quỹ mà tôi đăng ký tham gia.\n
Tôi sẽ chịu trách nhiệm cho quyết định đầu tư và chấp nhận các rủi ro đầu tư.\n`,
    save: 'Lưu',
    congboruiro: 'CÔNG BỐ RỦI RO VÀ CAM KẾT CỦA NHÀ ĐẦU TƯ.',
    title1: '1. Bản công bố rủi ro khi đầu tư vào quỹ mở.',
    content1: `Tôi, Nhà Đầu Tư, đã xem xét các thông tin được cung cấp về quỹ mở, và hiểu quỹ mở là gì.\n\nTôi, Nhà Đầu Tư, hiểu tôi đang tham gia vào một sản phẩm đầu tư tại thị trường chứng khoán Việt Nam, bao gồm cổ phiếu, trái phiếu, và các công cụ tài chính khác. Tôi hiểu rằng các khoản đầu tư vào chứng khoán sẽ chịu tác động từ rủi ro của thị trường, và vì vậy mà có thể thay đổi tăng hoặc giảm giá trị của khoản đầu tư.\n\nMức sinh lời kỳ vọng (nếu có) của Tôi trong các quỹ mở hoàn toàn không được cam kết hay đảm bảo. Vì vậy, Tôi hiểu rằng mức sinh lời thực tế có thể cao hơn hoặc thấp hơn mức sinh lời kỳ vọng hoặc mức sinh lời trong quá khứ của quỹ.\n\nKhoản đầu tư vào quỹ mở của Tôi có thể phải chịu thua lỗ nếu các chứng khoán được đầu tư vào không hoạt động hiệu quả hoặc thị trường không thuận lợi. Từ đó Tôi hiểu rằng một phần khoản đầu tư của tôi có thể bị mất mát do mức sinh lời của quỹ không như kỳ vọng.\n\n${stringApp.companyName} hoặc đối tác của ${stringApp.companyName} không thể đưa ra bất kỳ cam kết nào về lợi nhuận hay thua lỗ trên khoản đầu tư, cho dù là một cách trực tiếp hay gián tiếp. Tôi hiểu rằng ${stringApp.companyName} và đối tác của ${stringApp.companyName} không chịu và không có bất kỳ nghĩa vụ pháp lý nào phải bồi thường cho bất cứ khoản lỗ nào đối với các khoản đầu tư của Tôi vào quỹ mở.\n`,
    title2: '2. Cam kết của nhà đầu tư khi đầu tư vào quỹ mở.',
    content2: `Tôi có trách nhiệm am hiểu rõ Bản cáo bạch và Điều lệ của Quỹ mà tôi đăng ký tham gia.\n\nTôi sẽ chịu trách nhiệm cho quyết định đầu tư và chấp nhận các rủi ro đầu tư.\n\nTôi hiểu và chấp nhận ${stringApp.companyName} có thể phải cung cấp thông tin về tôi và tài khoản này cho cơ quan quản lý liên quan tại Việt Nam và/hoặc Sở thuế vụ Mỹ-IRS theo yêu cầu tuân thủ của luật FATCA.\n\nTôi, theo đây, cam kết các thông tin được cung cấp ở trên là trung thực và chính xác. Tôi đồng ý sẽ thông báo cho ${stringApp.companyName} ngay khi có sự thay đổi về các thông tin đã kê khai.\n\nTrong trường hợp tiền thanh toán mua chứng chỉ quỹ không khớp được tại kỳ giao dịch do lệnh mua không hợp lệ hoặc lệnh mua gửi quá giờ chốt sổ lệnh, tôi đồng ý chỉ thị cho Đại lý Phân phối giữ lại số tền thanh toán cho một (01) kỳ giao dịch gần nhất tiếp theo.\n\nĐiều khoản về chia sẻ thông tin: Nhà đầu tư tại đây đồng ý và thừa nhận rằng, ${stringApp.companyName} sẽ được quyền chia sẻ thông tin của Nhà Đầu Tư, kết quả đầu tư, các lệnh giao dịch đầu tư của Nhà Đầu Tư với (là đối tác giới thiệu Nhà Đầu Tư với ${stringApp.companyName}) cho mục đích đối chiếu, đánh giá hiệu quả đầu tư, mà không cần thêm bất kỳ chấp thuận nào từ phía Nhà Đầu Tư.\n\nPhí chuyển khoản: Nhà Đầu Tư phải chịu hoàn toàn chi phí chuyển khoản liên ngân hàng (nếu có) khi thực hiện các lệnh dịch mua, bán, và chuyển đổi đầu tư khi tham gia đầu tư sản phẩm của ${stringApp.companyName}.\n`,
    title3:
      'Ngoài ra, đang có thêm một phần nội dung cam kết cho NĐT khai khi có liên quan đến quốc tịch Mỹ trong Mục III. THÔNG TIN BỔ SUNG.',
    content3: `Bằng văn bản này, tôi xác nhận thông tin cung cấp ở trên là đúng, chính xác và đầy đủ.\n\nTrong phạm vi luật áp dụng, Tôi theo đây cho phép Công ty Cổ phần Quản lý Quỹ ${stringApp.companyName} được chia sẻ thông tin của tôi với cơ quan thuế nước sở tại hay nước ngoài khi cần thiết để thực hiện nghĩa vụ thuế của tôi tại nơi đó.\n\nKhi được yêu cầu bởi luật pháp hay cơ quan thuế nước sở tại hay nước ngoài. Tôi đồng ý và cho phép ${stringApp.companyName} được trực tiếp khấu trừ từ tài khoản của tôi theo đúng pháp luật hiện hành.\n\nTôi cam kết sẽ thông báo cho ${stringApp.companyName} trong vòng 30 ngày nếu có bất kỳ thay đổi nào đối với thông tin mà tôi đã cung cấp cho ${stringApp.companyName}.`,
  },
  transactionscreen: {
    // sao ke
    saokegiaodich: 'Sao kê giao dịch',
    chonquy: 'Chọn quỹ',
    theothoidiemgiaodich: 'Theo thời điểm giao dịch',
    saoke: 'Sao kê',
    tungay: 'Từ ngày',
    denngay: 'Đến ngày',
    //
    giaodich: 'Giao dịch',
    lenhchoxuly: 'Lệnh chờ xử lý',
    lichsugiaodich: 'Lịch sử giao dịch',
    lenhchomua: 'Lệnh chờ mua',
    lenhchoban: 'Lệnh chờ bán',
    lenhchochuyendoi: 'Lệnh chờ chuyển đổi',
    tongsolenh: 'Tổng số lệnh: ',
    quychuongtrinh: 'Quỹ - Chương trình',
    soluong: 'Số lượng',
    sotienmua: 'Số tiền mua',
    sotienmuavnd: `Số tiền mua ${stringApp.currency}`, // 'Số tiền mua (VNĐ)',
    ngaydatlenh: 'Ngày đặt lệnh',
    trangthai: 'Trạng thái',
    nhapmalenh: 'Nhập mã lệnh',
    ngay: 'Ngày',
    thoidiemgiaodich: 'Thời điểm giao dịch',
    chuacogiaodich: 'Hiện tại quý khách chưa tạo lệnh',
    // order buy detaisl
    thongtindautu: 'Thông tin đầu tư',
    quydautu: 'Quỹ đầu tư',
    chuongtrinh: 'Chương trình',
    loailenh: 'Loại lệnh',
    phiengiaodich: 'Phiên giao dịch',
    thongtinchuyenkhoan: 'Thông tin chuyển khoản',
    taikhoanthuhuong: 'Tài khoản thụ hưởng',
    tenthuhuong: 'Tên thụ hưởng',
    sotaikhoan: 'Số tài khoản',
    tennganhang: 'Tên ngân hàng',
    noidung: 'Nội dung',
    phuongthucchuyenkhoan:
      'Bạn đang chọn phương thức chuyển khoản qua ngân hàng.',
    soccqban: 'Số CCQ Bán',
    soccq: 'Số CCQ',
    ngaymua: 'Ngày mua',
    tgnamgiu: 'Thời gian nắm giữ',
    slban: 'Số lượng bán',
    phi: 'Phí',
    quymuctieu: 'Quỹ mục tiêu',
    giatriuoctinh: 'Giá trị ước tính',
    socqqchuyendoi: 'Số CQQ chuyển đổi',
    tongtien: 'Tổng tiền',
    ngaygiaodich: 'Ngày giao dịch',
    malenh: 'Mã lệnh',
    nav: 'NAV',
    sotien: 'Số tiền',
    navkytruoc: 'NAV kỳ trước',
    locgiaodich: 'Lọc giao dịch',
    xoa: 'Xoá',
    huy: 'Huỷ',
    apdung: 'Áp dụng',
    theoloailenh: 'Theo loại lệnh',
    tatca: 'Tất cả',
    lenhmua: 'Lệnh mua',
    lenhban: 'Lệnh bán',
    lenhmuahoandoi: 'Lệnh chuyển đổi mua',
    lenhbanhoandoi: 'Lệnh chuyển đổi bán',
    lenhhoandoi: 'Lệnh hoán đổi',
    toingay: 'Tới ngày',
    taolenhmua: 'Tạo lệnh mua',
    taolenhban: 'Tạo lệnh bán',
    taolenhchuyendoi: 'Tạo lệnh chuyển đổi',
    // dinh ky
    dinhky: 'Quản lý định kỳ',
    tinhtrang: 'Tình trạng',
    sotiendangkydautu: 'Số tiền đăng ký đầu tư',
    sokydaututoithieu: 'Số kỳ đầu tư tối thiểu',
    sokydadautu: 'Số kỳ đã đầu tư',
    sokylientuckhongthamgia: 'Số kỳ liên tục không tham gia',
    kydaututieptheo: 'Kỳ đầu tư tiếp theo',
    copy: 'COPY',
    nganhang: 'Ngân hàng',
    chinhanh: 'Chi nhánh',
    nguoihuongthu: 'Người hưởng thụ',
    lichsukhoplenh: 'Lịch sử khớp lệnh',
    taiphieuxacnhan: 'Tải phiếu xác nhận',
    kydautu: 'Kỳ đầu tư',
    sotiendautu: 'Số tiền đầu tư',
    ngaykhoplenh: 'Ngày khớp lệnh',
  },
  // setting screen
  settingscreen: {
    caidatvabaomat: 'Cài đặt & bảo mật',
    ngonngu: 'Ngôn ngữ',
    nhanthongbao: 'Nhận thông báo',
    faceid: 'Khuôn mặt/Vân tay',
    thongbao: 'Thông báo',
    content: 'Quý khách vui lòng nhập mật khẩu để kích hoạt chức năng này',
    matkhau: 'Mật khẩu',
    huy: 'Huỷ',
    xacnhan: 'Xác nhận',
    kichhoatthanhcong:
      'Quý khách vừa kích hoạt đăng nhập bằng vân tay/khuôn mặt thành công',
    xacnhanhuykichhoat: 'Bạn có chắc chắn muốn huỷ cài đặt vân tay/khuôn mặt?',
    matkhaucuaquykhachkhongdung: 'Mật khẩu của Quý khách không đúng',
    yeucauxoataikhoan: 'Yêu cầu xoá tài khoản',
    yeucauxoa: 'Yêu cầu xoá',
  },
  supportscreen: {
    hotrokhachhang: 'Hỗ trợ khách hàng',
    cskh: 'CSKH',
    email: 'Email',
    facebook: 'Facebook',
    trungtamchamsockhachhang: 'Trung tâm chăm sóc khách hàng',
    chuyenvientrogiup: 'Chuyên viên trợ giúp',
    hoten: 'Họ tên',
  },
  investmentscreen: {
    dautu: 'Đầu tư',
    sanpham: 'Sản phẩm',
    giadonviquykytruoc: 'Giá đơn vị quỹ kỳ trước',
    ngay: 'Ngày',
    dautungay: 'Đầu tư ngay',
    giatrihientai: 'Giá trị hiện tại',
    danhsachphiengiaodich: 'Danh sách phiên giao dịch',
    phiengiaodich: 'Phiên giao dịch',
    taolenhmua: 'Tạo lệnh mua',
    thaydoisovoidaunam: 'Kết quả đầu tư từ khi thành lập',
    taingay: 'tại ngày',
    denngay: 'đến ngày',
    Taingay: 'Tại ngày',
    giatriNAVCCQ: `Giá đơn vị quỹ gần nhất ${stringApp.currency}`, // 'Giá đơn vị quỹ gần nhất (VNĐ)',
    NAVtaingay: 'NAV tại ',
    tangtruongNAVCCQ: 'Tăng trưởng NAV/CCQ - ',
    NAVcaonhat: `NAV\ncao nhất`,
    NAVthapnhat: `NAV\nthấp nhất`,
    khongconoidunghienthi: 'Không có nội dung hiển thị',
    thang: 'Tháng',
    nam: 'Năm',
    tatca: 'Tất cả',
    NAV: 'NAV',
    navccq: 'NAV/CCQ',
    thongtinquy: 'Thông tin quỹ',
    xemchitiet: 'Xem chi tiết',
    xemthem: 'Xem thêm',
  },
  createordermodal: {
    huongdanthanhtoanbangqrcode: 'Hướng dẫn thanh toán bằng QR Code',
    giatrimua: 'Giá trị mua',
    nhapmatuvanvien: 'Nhập mã tư vấn viên',
    tudongtieptucdautu: 'Tự động tiếp tục đầu tư',
    ngaythanhtoanhangthang: 'Ngày thanh toán hàng tháng',
    datlenhban: 'Đặt lệnh bán',
    datlenhmua: 'Đặt lệnh mua',
    datlenhchuyendoi: 'Đặt lệnh chuyển đổi',
    matuvanvien: 'Mã tư vấn viên',
    xacnhan: 'Xác nhận',
    datlenh: 'Đặt lệnh',
    ketqua: 'Kết quả',
    hoac: 'Hoặc',
    thanhtoannhanhquaqrcode: 'Thanh toán nhanh qua QR Code',
    huongdanchuyenkhoan: 'Hướng dẫn chuyển khoản',
    xemqrcode: 'Xem QR code',
    taive: 'Tải về',
    motsonganhangchuahotroquetmaqr: 'Một số ngân hàng chưa hỗ trợ quét mã QR',
    quykhachvuilongdoichieuthongtintruockhichuyenkhoan:
      'Quý khách vui lòng đối chiếu Thông tin trước khi chuyển khoản',
    quaylai: 'Quay lại',
    bieuphimua: 'Biểu phí mua',
    thoigiandautulientuctoithieu: 'Thời gian đầu tư liên tục tối thiểu',
    thang: 'tháng',
    bieuphichuyendoi: 'Biểu phí chuyển đổi',
    bieuphiban: 'Biểu phí bán',
    thoigiannamgiu: 'Thời gian nắm giữ',
    // order buy
    ngaybatdauthamgia: 'Ngày bắt đầu tham gia',
    tudongtaolenhdinhkyhangthang: 'Tự động tạo lệnh định kỳ hàng tháng',
    chonsanpham: 'Chọn sản phẩm',
    chonchuongtrinh: 'Chọn chương trình',
    nhapsotienmua: 'Nhập số tiền mua',
    sotiendaututoithieu: 'Số tiền đầu tư tối thiểu',
    phimua: 'Phí mua',
    phichuyendoi: 'Phí chuyển đổi',
    xembieuphi: 'Xem biểu phí',
    thoidiemdongsolenh: 'Thời điểm đóng sổ lệnh ',
    nhadautuvuilongchuyentientruoc: 'Nhà đầu tư vui lòng chuyển tiền trước',
    thoidiemdongsolenhnhantien:
      'Tiền mua của Nhà đầu tư phải được ghi có vào tài khoản của Quỹ trước',
    phiengiaodich: 'Phiên giao dịch',
    thongtindautu: 'Thông tin đầu tư',
    quydautu: 'Quỹ đầu tư',
    chuongtrinh: 'Chương trình',
    loailenh: 'Loại lệnh',
    ngaydatlenh: 'Ngày đặt lệnh',
    sotienmua: 'Số tiền mua',
    phuongphapthanhtoan: 'Phương thức thanh toán',
    chuyenkhoanquanganhang: 'Chuyển khoản qua ngân hàng',
    vuilongchuyentientruoc: 'Vui lòng chuyển tiền trước',
    thongtinchuyenkhoan: 'Thông tin chuyển khoản',
    luuyttck:
      'Vui lòng copy chính xác các thông tin để đảm bảo lệnh được khớp.',
    tenthuhuong: 'Người thụ hưởng',
    sotaikhoan: 'Số tài khoản',
    nganhang: 'Ngân hàng',
    noidung: 'Nội dung',
    mua: 'Mua',
    datlenhmuathanhcong: 'Đặt lệnh mua thành công',
    camonquykhachdadautuvao: 'Cảm ơn quý khách đã đầu tư vào',
    muathem: 'Mua thêm',
    hoantat: 'Hoàn tất',
    xacnhanthanhtoan: 'Xác nhận thanh toán',
    contentxacnhanthanhtoan:
      'Quý khách xác nhận thông tin thanh toán cho khoản đầu tư với thông tin chuyển khoản dưới đây:',
    // order sell
    nhapsoluongban: 'Nhập số lượng bán',
    soluongtoithieukhongduoi: 'Số lượng tối thiểu không dưới',
    soluongkhadung: 'Số lượng khả dụng',
    ngaymua: 'Ngày mua',
    tgnamgiu: 'Thời gian nắm giữ',
    slban: 'Số lượng bán',
    phi: 'Phí',
    phiban: 'Phí bán',
    soluongban: 'Số lượng bán',
    ban: 'Bán',
    giatriuoctinhsauthuephi: 'Giá trị ước tính sau thuế, phí',
    datlenhbanthanhcong: 'Đặt lệnh bán thành công',
    camonquykhach: 'Cảm ơn Quý khách',
    // create order transder
    thongtinccqchuyendoi: 'Thông tin CCQ chuyển đổi',
    chonccqchuyendoi: 'Chọn quỹ chuyển đổi',
    navkitruoc: 'NAV kỳ trước',
    soluongchuyendoi: 'Số lượng chuyển đổi',
    thongtinccqmuctieu: 'Thông tin CCQ mục tiêu',
    chonccqmuctieu: 'Chọn quỹ mục tiêu',
    thongtinchuyendoi: 'Thông tin chuyển đổi',
    chuyendoi: 'Chuyển đổi',
    datlenhchuyendoithanhcong: 'Đặt lệnh chuyển đổi thành công',
    navccqkitruoc: 'NAV/CCQ kỳ trước',
    tatca: 'Tất cả',
  },
  assetscreen: {
    tongquantaisan: 'Tổng quan tài sản',
    sl: 'SL : ',
    chitiet: 'Chi tiết',
    ccq: 'CCQ',
    giamua: 'Giá mua',
    danhsachlenhmuadangnamgiu: 'Danh sách lệnh mua đang nắm giữ',
    tongsodanhsach: 'Tổng số danh sách:',
    quychuongtrinh: 'Quỹ - Chương trình',
    phiengiaodich: 'Phiên giao dịch',
    tonggiatridangnamgiu: 'Tổng giá trị đang nắm giữ',
    tonggiatridangchokhop: 'Tổng giá trị lệnh bán đang chờ khớp',
    tongtaisan: 'Tổng tài sản',
    soluong: 'Số lượng',
    giatridaututb: 'Giá trị đầu tư trung bình',
    navkitruoc: 'NAV kỳ trước',
    giatrihientai: 'Giá trị hiện tại',
    loilophantram: `Lời/Lỗ(%)`,
    loilodong: `Lời/Lỗ(đ)`,
    lenh: 'Lệnh',
    chuongtrinh: 'Chương trình',
    // details
    sotkdautu: 'Số TK đầu tư',
    giatrithitruong: 'Giá trị thị trường',
    sldonviquy: 'SL đơn vị quỹ',
    sotiendadautu: 'Số tiền đã đầu tư',
    sotiendadautuconlai: 'Số tiền đã đầu tư còn lại',
    ngaygiaodichdautien: 'Ngày giao dịch đầu tiên',
    lailodathuchien: `Lãi/Lỗ đã thực hiện`,
    lailochuathuchien: `Lãi/Lỗ chưa thực hiện`,
    loilo: 'Lời/Lỗ',
    tongdautu: 'Tổng đầu tư',
  },
  overviewscreen: {
    tongquantaisan: 'Tổng quan tài sản',
    tongsocqq: 'Tổng số CQQ',
    giatrihientai: 'Giá trị hiện tại',
    loilo: 'Lời/lỗ',
    banchuacotaisan: 'Bạn chưa có tài sản',
    contentnondata: `Bạn cần tham gia đầu tư thực hiện các giao dịch bằng cách vào mục "Đầu tư" bên dưới`,
    tonggiatridaututb: 'Tổng giá trị đầu tư TB',
    sotiendautu: `Tổng giá vốn`, // 'Số tiền đầu tư (VNĐ)',
    sotiendautuvnd: `Tổng giá vốn ${stringApp.currency}`, // 'Số tiền đầu tư (VNĐ)',
    tongloilo: 'Tổng lời/lỗ',
    taisan: 'Tài sản',
    soluongCCQ: 'Số lượng CCQ',
    //
    NAVhientai: 'NAV',
    tonggiatridaututrungbinh: `Tổng giá vốn ${stringApp.currency}`, // 'Số tiền đầu tư (VNĐ)',
    giamuatrungbinh: `Giá mua trung bình/CCQ`,
    tonggiatrithitruong: `Tổng giá trị thị trường ${stringApp.currency}`, // 'Tổng giá trị thị trường (VNĐ)',
  },
  notificationscreen: {
    thongbao: 'Thông báo',
    huy: 'Huỷ',
    xoamucdachon: 'Xoá mục đã chọn',
  },
  MiniEkycModal: {
    xacthucthongtin: 'Xác thực thông tin',
  },
  controlekyc: {
    dangnhap: 'Đăng nhập',
    xacthucthongtin: 'Xác thực thông tin - eKYC',
    content: `Để hoàn tất việc mở tài khoản trên ${stringApp.appName}, vui lòng thực hiện các bước xác thực thông tin dưới đây`,
    chuphinhmattruoc: '1. Chụp hình mặt trước, mặt sau CMND/CCCD',
    chuphinhchandung: '2. Chụp hình chân dung',
    batdau: 'Bắt đầu',
    thuchiensau: 'Thực hiện sau',
  },
  digitalsignature: {
    chukyso: 'Chữ ký số',
    xemgiaydkgd: 'Xem giấy ĐKGD',
    uploadanh: 'Upload ảnh',
    kyso: 'Ký số',
    xoa: 'Xoá',
    xacnhan: 'Xác nhận',
    contentnoti: 'Quý khách hàng vui lòng ký vào khu vực trên đây',
    giaydkgd: 'Giấy ĐKGD',
    mayanh: 'Máy ảnh',
    thuvien: 'Thư viện',
    huy: 'Huỷ',
    chonhinhanh: 'Chọn hình ảnh',
    taive: 'Tải về',
    hopdongdientu: 'Giấy đăng ký mở tài khoản giao dịch',
    hopdongdientutype2: 'Giấy đăng ký mở tài khoản\ngiao dịch',
    xemtruoc: 'Xem trước',
    kydientu: 'Ký điện tử',
    taichuky: 'Tải chữ ký',
    chukycuaban: 'Chữ ký của bạn',
    taihopdongdaky: 'Tải hợp đồng đã ký',
    taihopdongmotaikhoan: 'Tải giấy đăng ký mở tài khoản giao dịch',
    contentdownload: `Vui lòng xem trước thông tin trên Giấy đăng ký mở tài khoản giao dịch của Quý khách và thực hiện ký điện tử bằng cách nhấn vào nút 'Ký điện tử' bên dưới`,
    contentdownload2: `Giấy đăng ký mở tài khoản giao dịch của Quý khách đã được xác nhận ký điện tử. Quý nhà đầu tư có thể tải Giấy đăng ký mở tài khoản giao dịch đã ký bằng cách nhấn vào nút bên dưới`,
  },
  feetable: {
    bieuphi: 'Biểu phí',
  },
  alert: {
    khuonmatkhonghople: `Hình chụp khuôn mặt không hợp lệ. Vui lòng thực hiện lại!`,
    xoataikhoanthanhcong: 'Xoá tài khoản thành công',
    ngaycap: 'Ngày cấp nhỏ hơn ngày sinh',
    vuilongchondayduthongtin: 'Vui lòng chọn đầy đủ thông tin !',
    kythanhcong: 'Quý khách đã ký điện tử thành công!',
    capnhatmucdoruirothanhcong: 'Cập nhật mức độ rủi ro thành công !',
    taochukysothanhcong: 'Quý khách đã ký điện tử thành công!',
    logout: 'Đăng xuất',
    dinhdanganhkhongphuhop:
      'Hình chữ ký bạn tải lên không đúng định dạng. Vui lòng chọn các file hình có định dạng: .png,.jpg, .jpeg',
    desau: 'Để sau',
    taithanhcong: 'Tải thành công',
    kydientu: 'Ký điện tử',
    contentlogout: 'Bạn chắc chắn muốn đăng xuất?',
    dong: 'Đóng',
    xoalenh: 'Đã xoá lệnh thành công',
    doimatkhauthanhcong: 'Đổi mật khẩu thành công',
    doiemailthanhcong: 'Đổi email thành công',
    capnhatdiachithanhcong: 'Cập nhật địa chỉ thành công',
    vuilongnhapdayduthongtindiachi: 'Vui lòng nhập đầy đủ thông tin địa chỉ',
    vuilongnhapdayduthongtincanhan: 'Vui lòng nhập đầy đủ thông tin cá nhân',
    capnhatthongtincanhanthanhcong: 'Cập nhật thông tin cá nhân thành công',
    capnhattaikhoannganhang:
      'Cập nhật thông tin tài khoản ngân hàng thành công',
    capnhatthongtintaikhoannganhangthanhcong:
      'Nhà đầu tư liên hệ với Công ty Quản lý quỹ để cập nhật tài khoản ngân hàng',
    dangxuat: 'Đăng xuất',
    lichxong: 'Xong',
    // thong bao
    thongbao: 'Thông báo',
    dungluongtoida: 'Dung lượng file tối đa 5MB',
    vuilongnhapdayduthongtintaikhoannganhang:
      'Vui lòng nhập đầy đủ thông tin tài khoản ngân hàng',
    dongy: 'Đồng ý',
    huybo: 'Huỷ bỏ',
    thulai: 'Thử lại',
    vuilongnhapmatkhau: 'Vui lòng nhập mật khẩu',
    vuilongnhaptendangnhap: 'Vui lòng nhập tên đăng nhập',
    dasaochepvaobonhodem: 'Đã sao chép thông tin nội dung vào bộ nhớ đệm',
    dangnhapvantaythatbai:
      'Tính năng đăng nhập bằng Vân tay/Khuôn mặt chưa được cài đặt.Vui lòng đăng nhập bằng mật khẩu và kiểm tra lại',
    thatbai: 'Thất bại',
    daxayraloi: 'Đã xảy ra lỗi. Vui lòng thử lại',
    // order
    matkhaucuphaikhacmatkhaumoi: `Mật khẩu cũ phải khác mật khẩu mới`,
    emailcuphaikhacemailmoi: `Email cũ phải khác Email mới`,
    xacnhanxoalenhgiaodich: 'Xác nhận xoá lệnh giao dịch ?',
    chuadutuoi:
      'Theo quy định về độ tuổi được giao dịch Quỹ phải từ 18 tuổi trở lên. Chúng tôi rất tiếc phải từ chối thông tin đăng ký của nhà đầu tư.',
    nhapotpquasoluong:
      'Sai OTP quá 3 lần. Vì lý do bảo mật vui lòng đăng nhập lại.',
    registernhapquaotp:
      'Giao dịch của Quý Khách đã hết hiệu lực. Vui lòng tạo giao dịch mới',
    ekycfail:
      'Chúng tôi không thể xác minh thông tin bạn đang cung cấp vì chất lượng hình chụp không tốt. Vui lòng làm lại!',
    ekycfail3: `Chúng tôi không thể xác minh thông tin bạn đang cung cấp vì chất lượng hình chụp không tốt. Quý khách vui lòng khai báo thông tin cá nhân thủ công.`,
    dangkytaikhoanthanhcong:
      'Quý khách đã đăng ký tài khoản thành công. Trường hợp cần tư vấn thêm, Quý khách vui lòng liên hệ theo số (84-28) 3810 0888 – Ext: 5555 hoặc (84) 983 070481.',
    dangnhapsai: 'Tên đăng nhập hoặc mật khẩu của Quý khách không đúng',
  },
  //
  component: {
    ngay: 'Ngày',
    thang: 'Tháng',
    nam: 'Năm',
  },
};
