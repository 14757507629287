import {Div} from 'components';
import React from 'react';
import {getProductListSort} from 'reducer/asset';
import {useAppSelector} from 'store/hooks';
import ItemListAssetVerti from './ItemListAssetVerti';

interface Props {
  itemFocus: any;
  setItemFocus?: (e: any) => void;
}

function ListAssetVerti({setItemFocus}: Props) {
  const productList = useAppSelector(state => getProductListSort(state));

  const keyExtractor = (item: any, index: number) => `key${item.id}${index}`;

  const renderItem = (p: {item: any; index: number}) => {
    return <ItemListAssetVerti data={p.item} />;
  };

  const ListHeaderComponent = () => {
    return <Div width={17} />;
  };

  const ListFooterComponent = () => {
    return <Div height={17} />;
  };
  const ItemSeparatorComponent = () => {
    return <Div height={10} />;
  };

  return (
    <Div marginTop={20}>
      {productList?.map((item: any, index: number) => {
        return (
          <Div key={index}>
            <Div width={17} height={10} />
            <ItemListAssetVerti data={item} />
          </Div>
        );
      })}
    </Div>
  );
}

export default React.memo(ListAssetVerti);
