import {urlApp} from 'constant';
import {doPostAxios} from './axios';
// import ReactNativeBlobUtil from 'react-native-blob-util';

const baseURL = urlApp.APIURL;
export function uploadFile(
  params: {
    fileBase64: string;
  },
  progress?: (p: number) => void,
) {
  return new Promise(async (rs, rj) => {
    try {
      const r = await doPostAxios(`upload/base64`, {
        fileBase64: params.fileBase64.replace('data:image/png;base64,', ''),
      });
      if (r.status == 200) {
        rs(r.data);
        return r.data;
      }
      rj(r);
      throw r;
    } catch (error) {
      rj(error);
      throw error;
    }
  });
}
