export enum urlApp {
  // // test -------------------------------------------
  APIURL = 'https://apis.fplatform.fincorp.vn/web/v1/', //test/dev//
  DomainName = 'https://daiichi.mobile', // moi doi tac sua origin daichi
  // DomainName = 'https://fplatform.mobile', // moi doi tac sua origin daichi

  // // // //uat -------------------------------------------
  // APIURL = 'https://oapis.fplatform-uat.fincorp.vn/web/v1/', //main//
  // DomainName = 'https://daiichi.mobile', // moi doi tac sua origin daichi

  // live -------------------------------------------
  // APIURL = 'https://oapis.fplatform-live.fincorp.vn/web/v1/', //live//
  // DomainName = 'https://daiichi.mobile', // moi doi tac sua origin daichi
}
