import {Button, ButtonBorder, Div, ImageView, Label} from 'components';
import {Ecolors, EStyle, Icons} from 'constant';
import React, {useImperativeHandle, useRef, useState} from 'react';
import BottomSheetDialog from './BottomSheetDialog';

interface p {
  title: string;
  content: string;
  titleClose: string;
  type?: string | number;
  titleCancel: string;
  multilanguage?: boolean;
  showError?: boolean;
  titleConfirm: string;
  error: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
}

function AlertCp({}, ref: React.Ref<unknown> | undefined) {
  const [state, setState] = useState<p>({
    title: '',
    type: 1,
    content: '',
    error: false,
    titleClose: '',
    titleConfirm: '',
    titleCancel: '',
    multilanguage: false,
    showError: false,
    onCancel: () => {},
    onClose: () => {},
    onConfirm: () => {},
  });
  const sheetRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    show: (e: p) => {
      sheetRef.current.show();
      setState(a => {
        return {
          ...a,
          ...e,
        };
      });
    },
    hide: () => {
      sheetRef.current.hide();
    },
  }));
  return (
    <BottomSheetDialog
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      ref={sheetRef}>
      {!!state?.showError ? (
        <Div
          width={337}
          borderRadius={5}
          style={EStyle.shadow}
          backgroundColor={Ecolors.whiteColor}
          minHeight={193}>
          <Div
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}>
            <ImageView
              marginTop={31}
              source={Icons.erroricon}
              widthHeight={57}
              resizeMode={'contain'}
            />
            <Label
              margin={20}
              multilanguage={state?.multilanguage ?? true}
              textAlign={'center'}>
              {state?.content ||
                'Đã hết phiên giao dịch. Vui lòng đăng nhập lại !'}
            </Label>
          </Div>
          <Button
            width={'100%'}
            height={46}
            borderTopWidth={1}
            borderTopColor={Ecolors.spaceColor}
            alignItems={'center'}
            justifyContent={'center'}
            onPress={() => {
              state?.onClose && state?.onClose();
              sheetRef.current.hide();
            }}>
            <Label
              size={16}
              color={Ecolors.linkColor}
              fontWeight={'500'}>{`alert.thulai`}</Label>
          </Button>
        </Div>
      ) : (
        <Div
          width={337}
          borderRadius={5}
          style={EStyle.shadow}
          backgroundColor={Ecolors.whiteColor}
          minHeight={193}>
          {/* header */}
          <Div
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            borderBottomWidth={1}
            borderBottomColor={Ecolors.spaceColor}>
            <Div heightWidth={46} />
            <Div
              flexDirection={'row'}
              flex={1}
              alignItems={'center'}
              justifyContent={'center'}>
              <Label fontWeight={'700'}>{state?.title || ''}</Label>
            </Div>
            <Button
              onPress={() => {
                state?.onClose && state?.onClose();
                sheetRef.current.hide();
              }}
              widthHeight={46}
              alignItems={'center'}
              justifyContent={'center'}>
              <ImageView
                source={Icons.close}
                widthHeight={20}
                resizeMode={'contain'}
              />
            </Button>
          </Div>
          {/* content */}
          <Div
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            marginTop={28}
            marginBottom={39}
            paddingHorizontal={35}>
            <Label
              multilanguage={state?.multilanguage ?? true}
              textAlign={'center'}>
              {state?.content || ''}
            </Label>
          </Div>
          {state?.error ? (
            <Div
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              paddingHorizontal={14}
              paddingBottom={13}>
              <ButtonBorder
                type={1}
                width={148}
                title={state?.titleClose}
                onPress={() => {
                  sheetRef.current.hide();
                  // route?.params?.onCancel && route?.params?.onCancel();
                }}
              />
            </Div>
          ) : state?.type == 1 ? (
            <Div
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              paddingHorizontal={14}
              paddingBottom={13}>
              <ButtonBorder
                type={2}
                width={148}
                title={state?.titleCancel}
                onPress={() => {
                  sheetRef.current.hide();
                  state?.onCancel && state?.onCancel();
                }}
              />
              <ButtonBorder
                type={1}
                width={148}
                title={state?.titleConfirm}
                onPress={() => {
                  sheetRef.current.hide();
                  state?.onConfirm && state?.onConfirm();
                }}
              />
            </Div>
          ) : (
            <Div
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              paddingHorizontal={14}
              paddingBottom={13}>
              <ButtonBorder
                type={1}
                width={148}
                title={state?.titleClose}
                onPress={() => {
                  sheetRef.current.hide();
                  state?.onConfirm && state?.onConfirm();
                }}
              />
            </Div>
          )}
        </Div>
      )}
    </BottomSheetDialog>
  );
}

export default React.forwardRef(AlertCp);
