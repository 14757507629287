import {Button, Div, ImageView, Label, LoadingIndicator} from 'components';
import {Ecolors, EStyle, Icons, stringApp} from 'constant';
import React, {useState} from 'react';
// import ReactNativeBlobUtil from 'react-native-blob-util';
// import {PERMISSIONS} from 'react-native-permissions';
// import RNFS from 'react-native-fs';
import {useAppSelector} from 'store/hooks';
import {downloadFileWebview, widthScale} from 'utils';

function CardSignature() {
  const currentUser = useAppSelector<any>(state => state.authen.currentUser);
  const hardProfile = !!currentUser.investmentProfile?.isReceivedHardProfile;
  const {name, investmentProfile} = currentUser;
  const [loading, setLoading] = useState<boolean>(false);
  const I18nState = useAppSelector(state => state.languages.I18nState);

  const switchStatusEsign = (p: {
    hardProfile: boolean;
    I18nState: 'vi' | 'en';
  }) => {
    if (p.hardProfile) {
      if (p.I18nState == 'vi') {
        return 'Đã ký';
      }
      return 'Full submission';
    }
    if (p.I18nState == 'vi') {
      return 'Chưa ký';
    }
    return 'No submission';
  };

  const downloadConfirm = async () => {
    downloadFileWebview({
      url: `esignature/download-contract`,
      setLoading: setLoading,
    });
    return;
  };

  return (
    <>
      {/* {!loading && (
        <Div
          flex={1}
          width={'100%'}
          height={'100%'}
          screen={true}
          backgroundColor={Ecolors.transparentLoading}
          alignItems={'center'}
          justifyContent={'center'}
          position={'absolute'}
          zIndex={9999999}
          elevation={999999}>
          <LoadingIndicator color={Ecolors.mainColor} />
        </Div>
      )} */}
      <Div
        width={343}
        marginVertical={15}
        paddingVertical={16}
        borderRadius={8}
        borderWidth={0.8}
        borderColor={Ecolors.bordercolor}
        flexDirection={'row'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        paddingLeft={20}
        paddingRight={17}
        backgroundColor={Ecolors.whiteColor}
        style={EStyle.shadowItem}>
        <Div
          widthHeight={40}
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={5}
          overflow={'hidden'}
          borderWidth={0.8}
          borderColor={Ecolors.bordercolor}>
          <ImageView
            source={Icons.iconapp}
            // widthHeight={30}
            style={{
              width: widthScale(40),
              height: widthScale(40),
            }}
            resizeMode={'cover'}
          />
        </Div>
        <Div
          flex={1}
          paddingHorizontal={10}
          flexDirection={'column'}
          alignItems={'flex-start'}>
          <Label
            size={16}
            color={Ecolors.mainColor}
            fontWeight={'700'}
            multilanguage={false}>
            {stringApp.companyName}
          </Label>
          <Label
            marginTop={2}
            size={14}
            color={Ecolors.grayColor}
            multilanguage={false}>
            {investmentProfile?.number || ''}
          </Label>
          {/* <Button
            onPress={() => {
              downloadConfirm();
            }}>
            {loading ? (
              <ActivityIndicator color={Ecolors.mainColor} size={'small'} />
            ) : (
              <Label marginTop={7} size={14} color={Ecolors.linkColor}>
                {hardProfile
                  ? `digitalsignature.taive`
                  : `digitalsignature.xemtruoc`}
              </Label>
            )}
          </Button> */}
        </Div>

        <Div
          flexDirection={'row'}
          paddingTop={12}
          alignItems={'center'}
          justifyContent={'center'}>
          <Label
            size={14}
            color={hardProfile ? Ecolors.growColor : Ecolors.redColor}
            multilanguage={false}>
            {switchStatusEsign({hardProfile, I18nState})}
          </Label>
          <ImageView
            marginLeft={10}
            widthHeight={17}
            resizeMode={'contain'}
            source={hardProfile ? Icons.check : Icons.uncheck}
          />
        </Div>
      </Div>
      <Div
        alignItems={'center'}
        paddingHorizontal={20}
        justifyContent={'center'}>
        <Label textAlign={'center'} size={14}>
          {hardProfile
            ? `digitalsignature.contentdownload2`
            : `digitalsignature.contentdownload`}
        </Label>
      </Div>
      <Div
        width={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingBottom={20}>
        <Button
          width={340}
          height={48}
          flexDirection={'row'}
          marginTop={18}
          onPress={() => {
            downloadConfirm();
          }}
          borderRadius={5}
          borderWidth={0.8}
          borderColor={Ecolors.mainColor}
          backgroundColor={Ecolors.spaceColor}
          alignItems={'center'}
          justifyContent={'center'}>
          {loading ? (
            <LoadingIndicator color={Ecolors.mainColor} />
          ) : (
            <>
              <ImageView
                source={Icons.download}
                widthHeight={18}
                resizeMode={'contain'}
                marginRight={10}
              />
              <Label fontWeight={'700'} size={15}>
                {/* {hardProfile
                  ? `digitalsignature.taihopdongdaky`
                  : `digitalsignature.taihopdongmotaikhoan`} */}
                {`digitalsignature.taihopdongmotaikhoan`}
              </Label>
            </>
          )}
        </Button>
      </Div>

      <Div
        alignItems={'center'}
        paddingHorizontal={20}
        justifyContent={'center'}>
        {!investmentProfile?.isReceivedHardProfile && I18nState == 'en' && (
          <Label
            size={14}
            fontWeight={'700'}
            textAlign={'center'}
            multilanguage={false}>
            {I18nState == 'en'
              ? `If you're US-CITIZEN, please send FATCA document to our company`
              : ''}
          </Label>
        )}
      </Div>
    </>
  );
}

export default React.memo(CardSignature);
