import {BottomSheetDialog, Button, ImageView, Label} from 'components';
import {Ecolors, Icons} from 'constant';
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import Calendar from './Calendar';

const ItemCalendar = (
  p: {
    title: string;
    onChangeTime?: (t: any) => void;
    value?: any;
    width?: number;
    height?: number;
    type?: 'all' | 'month' | 'year';
    initValue?: any;
  },
  ref,
) => {
  const [time, setTime] = useState<string>('');
  const [value, setValue] = useState<any>({});
  const bottomSheetUpload = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    clearData: () => {
      setTime('');
      p.onChangeTime && p.onChangeTime(null);
    },
  }));

  useEffect(() => {
    if (!!p.initValue) {
      const a = new Date(p.initValue);
      const d = `0000${a.getDate()}`;
      const m = `0000${a.getMonth() + 1}`;
      const y = `0000${a.getFullYear()}`;
      const dd = d.slice(d.length - 2);
      const mm = m.slice(m.length - 2);
      const yy = y.slice(y.length - 4);
      setTime(`${dd}/${mm}/${yy}`);
      const obj = {
        day: {
          value: dd,
        },
        month: {
          value: mm,
        },
        year: {
          value: yy,
        },
      };
      setValue(obj);
      if (p.onChangeTime) {
        p.onChangeTime(obj);
      }
    }
  }, [p.initValue]);

  const bindData = t => {
    setValue(t);
    if (p.onChangeTime) {
      p.onChangeTime(t);
    }
    switch (p.type) {
      case 'year':
        setTime(`${t.year?.value}`);
        return;
      case 'month':
        setTime(`${t.month?.value}`);
        return;
      default:
        setTime(`${t.day?.value}/${t.month?.value}/${t.year?.value}`);
    }
  };

  return (
    <>
      <BottomSheetDialog
        style={{
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
        ref={bottomSheetUpload}>
        <Calendar
          onCancel={() => {
            bottomSheetUpload.current.hide();
          }}
          onAccept={t => {
            bottomSheetUpload.current.hide();
            bindData(t);
          }}
          value={value}
          type={p.type || 'all'}
        />
      </BottomSheetDialog>
      <Button
        onPress={() => {
          bottomSheetUpload.current.show();
        }}
        width={p.width || 164}
        height={p.height || 36}
        paddingHorizontal={16}
        borderRadius={5}
        borderWidth={0.5}
        borderColor={Ecolors.bordercolor}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Label size={14} multilanguage={false}>
          {time || p.title}
        </Label>
        <ImageView
          widthHeight={15}
          resizeMode={'contain'}
          source={Icons.calendar}
        />
      </Button>
    </>
  );
};

export default React.forwardRef(ItemCalendar);
