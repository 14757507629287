import {Ecolors} from 'constant';
import * as htmlToImage from 'html-to-image';
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Animated, StyleSheet, View} from 'react-native';
import {PanGestureHandler} from 'react-native-gesture-handler';
import Svg, {Path} from 'react-native-svg';
import {getUuid} from 'utils';
// import ViewShot from 'react-native-view-shot';
var genUID = getUuid();

function DrawLine(p: {setIsVisible: (t: boolean) => void}, ref: any) {
  const viewshotRef = useRef<any>(null);
  const pan = useRef(new Animated.ValueXY()).current;
  const [stateLine, setStateLine] = useState<any>({});

  useImperativeHandle(ref, () => ({
    accept: (callback: (t: string) => void) => {
      try {
        const body: any = document.getElementById('View');
        htmlToImage.toPng(body).then(function (dataUrl) {
          callback(dataUrl);
          // download(dataUrl, 'my-node.png');
        });
        return;
      } catch (error) {}
    },
    clear: () => {
      setStateLine({});
    },
  }));

  const onGestureEvent = Animated.event(
    [
      {
        nativeEvent: {
          x: pan.x,
          y: pan.y,
        },
      },
    ],
    {
      listener: (event: any) => {
        const x = event.nativeEvent.x;
        const y = event.nativeEvent.y;
        setStateLine(a => {
          return {
            ...a,
            [genUID]: [...(a?.[`${genUID}`] || []), {x, y}],
          };
        });
      },
      useNativeDriver: true,
    },
  );

  useEffect(() => {
    if (!!Object.keys(stateLine)?.length) {
      p.setIsVisible(true);
    } else {
      p.setIsVisible(false);
    }
  }, [stateLine]);

  return (
    <PanGestureHandler
      onBegan={() => {
        genUID = getUuid();
      }}
      onEnded={() => {
        genUID = '';
      }}
      onGestureEvent={onGestureEvent}>
      <View nativeID="View" style={s.ff} ref={viewshotRef}>
        <Svg width={'100%'} height={'100%'}>
          {Object.keys(stateLine).map((item: any, index: number) => {
            const consvar = stateLine[item];
            const d = `${consvar.map((item: any, index: number) => {
              if (index == 0) {
                return `M${item.x} ${item.y}`;
              }
              return `L${item.x} ${item.y}`;
            })}`;
            return (
              <Path
                key={item}
                d={d}
                fill="none"
                stroke={Ecolors.textColor}
                strokeWidth="2"
              />
            );
          })}
        </Svg>
      </View>
    </PanGestureHandler>
  );
}

const s = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  ff: {
    flex: 1,
    backgroundColor: 'transparent',
  },
});
export default React.forwardRef(DrawLine);
