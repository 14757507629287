import {StatusBar} from 'expo-status-bar';
import React, {useState} from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {AppContainer} from './src/container';
export default function App() {
  const [isLoadingComplete, setIsLoadingComplete] = useState(true);
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <>
        <SafeAreaProvider>
          <AppContainer />
          <StatusBar hidden={true} />
        </SafeAreaProvider>
      </>
    );
  }
}
