import {NavigationContainer} from '@react-navigation/native';
import {toastRef} from 'components/Toast';
import ToastRoot from 'components/Toast/ToastRoot';
import React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {Provider} from 'react-redux';
import {store} from 'store';
import LinkingConfiguration from './LinkingConfiguration';
import Navi from './Navi';

function AppContainer() {
  // useEffect(() => {
  //   handleNotification();
  //   return () => {};
  // }, []);

  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <NavigationContainer linking={LinkingConfiguration} independent={true}>
          <ToastRoot ref={toastRef} />
          <Navi />
        </NavigationContainer>
      </Provider>
    </SafeAreaProvider>
  );
}

export default React.memo(AppContainer);
